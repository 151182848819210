import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Products from "../components/Products";
import SEO from "../components/SEO";

const UdstyrPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(filter: { context: { productType: { eq: "udstyr" } } }) {
        edges {
          node {
            context {
              productTitle
              productImage
              productPath
              productPrice
              productOldprice
              productLink
              productId
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Find tilbud på træningsudstyr til hjemmet"
        description="Spar en mas
        se penge og find de bedste priser på træningsudstyr hos os. Det behøver ikke at være dyrt, at komme i form."
      />
      <h1 className="font-bold text-xl">Træningsudstyr til din hjemmetræning</h1>
      <p className="max-w-xl pb-4">
        Under udstyr, finder du alt fra træningsmaskiner til træningshandsker
        mm. Gør det sjovt at træne derhjemme og byg dit helt eget sted til
        fitness. Du finder de bedste produkter fra danske forhandlere, såsom
        Odin motionscykler og Reebok Racks til håndvægte.
      </p>
      <Products data={data} />
    </>
  );
};

export default UdstyrPage;
